import { createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: { open: false },
  reducers: {
    openContactDialog: (state) => {
      state.open = true;
    },
    closeContactDialog: (state) => {
      state.open = false;
    },
  },
});

export const { openContactDialog, closeContactDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
