import { configureStore } from '@reduxjs/toolkit';
import loaderReducer from './features/loader';
import dialogReducer from './features/dialog';
const store = configureStore({
  reducer: {
    loader: loaderReducer,
    dialog: dialogReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
