import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: { open: false },
  reducers: {
    openLoader: (state) => {
      state.open = true;
    },
    closeLoader: (state) => {
      state.open = false;
    },
  },
});

export const { openLoader, closeLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
