/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
import { httpClient } from './api';
import store from '../store';
import { closeLoader, openLoader } from '../store/features/loader';

const BASE_URL = 'http://3.80.38.16:8082';

export default {
  setupInterceptors: () => {
    httpClient.interceptors.request.use(
      async (config) => {
        store.dispatch(openLoader());
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );

    httpClient.interceptors.response.use(
      (response) => {
        store.dispatch(closeLoader());
        return response;
      },
      (error) => {
        store.dispatch(closeLoader());
        if (error.response && error.response.status === 401) {
        }
        if (error.response && error.response.status === 403) {
        }

        return Promise.reject(error);
      }
    );
  },
};
