import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { pre } from "./constants.jsx";
import httpService from "./AxiosSetup/axios-interceptors.js";
import Loader from "./components/ui/Loader";

httpService.setupInterceptors();

const Home = lazy(() => import("./views/Home/index.jsx"));
const Orchestrator = lazy(() => import("./views/Orchestrator/index.jsx"));
const Marketplace = lazy(() => import("./views/Marketplace/index.jsx"));
const MarketPlayers = lazy(() => import("./views/MarketPlayers/index.jsx"));
const Producers = lazy(() => import("./views/PRODUCERS/index.jsx"));
const Consumers = lazy(() => import("./views/Consumers/index.jsx"));
const Enablers = lazy(() => import("./views/Enablers/index.jsx"));
const Traders = lazy(() => import("./views/Traders/index.jsx"));
const About = lazy(() => import("./views/About/index.jsx"));
const OurValue = lazy(() => import("./views/OurValue/index.jsx"));
const Team = lazy(() => import("./views/Team/index.js"));
const Research = lazy(() => import("./views/Research/index.jsx"));
const IndexSolutions = lazy(() => import("./views/IndexSolution/index.jsx"));
const MarketResearch = lazy(() => import("./views/MarketResearch/index.jsx"));
const DataAnalytics = lazy(() => import("./views/dataAnalytics/index.jsx"));
export const routesArr = {
  HOMEPAGE_REACT: {
    path: `${pre}/`,
    name: "HOMEPAGE_REACT",
    component: Home,
    id: uuid(),
  },
  ORCHESTRATOR_REACT: {
    path: `${pre}/orchestrator`,
    name: "ORCHESTRATOR_REACT",
    component: Orchestrator,
    id: uuid(),
  },
  RESEARCH_REACT: {
    path: `${pre}/research`,
    name: "RESEARCH_REACT",
    component: Research,
    id: uuid(),
  },
  INDEXSOLUTION_REACT: {
    path: `${pre}/indexsolutions`,
    name: "INDEXSOLUTION_REACT",
    component: IndexSolutions,
    id: uuid(),
  },
  MARKETRESEARCH_REACT: {
    path: `${pre}/marketresearch`,
    name: "MARKETRESEARCH_REACT",
    component: MarketResearch,
    id: uuid(),
  },
  DATAANALYTICS_REACT: {
    path: `${pre}/analytics`,
    name: "DATAANALYTICS_REACT",
    component: DataAnalytics,
    id: uuid(),
  },
  MARKETPLACE_REACT: {
    path: `${pre}/marketplace`,
    name: "MARKETPLACE_REACT",
    component: Marketplace,
    id: uuid(),
  },
  MARKETPLAYERS_REACT: {
    path: `${pre}/marketplayers`,
    name: "MARKETPLAYERS_REACT",
    component: MarketPlayers,
    id: uuid(),
  },
  PRODUCERS_REACT: {
    path: `${pre}/producers`,
    name: "PRODUCERS_REACT",
    component: Producers,
    id: uuid(),
  },
  CONSUMERS_REACT: {
    path: `${pre}/consumers`,
    name: "CONSUMERS_REACT",
    component: Consumers,
    id: uuid(),
  },
  ENABLERS_REACT: {
    path: `${pre}/enablers`,
    name: "ENABLERS_REACT",
    component: Enablers,
    id: uuid(),
  },
  TRADERS_REACT: {
    path: `${pre}/traders`,
    name: "TRADERS_REACT",
    component: Traders,
    id: uuid(),
  },
  ABOUT_REACT: {
    path: `${pre}/about`,
    name: "ABOUT_REACT",
    component: About,
    id: uuid(),
  },
  OUR_VALUE_REACT: {
    path: `${pre}/our-value`,
    name: "OUR_VALUE_REACT",
    component: OurValue,
    id: uuid(),
  },
  TEAM_REACT: {
    path: `${pre}/team`,
    name: "TEAM_REACT",
    component: Team,
    id: uuid(),
  },
};

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <>
          <Loader />
        </>
      }>
      <Routes>
        {Object.values(routesArr).map((routeItem, index) => (
          <Route
            path={routeItem.path}
            key={routeItem.id}
            element={<routeItem.component />}
          />
        ))}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
