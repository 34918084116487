import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const Loader = () => {
  const open = useSelector((state) => state.loader.open);

  return open ? (
    <div
      className="fixedInlinecss"
    >
      <CircularProgress size={75} sx={{ color: "rgb(0,102,153)" }} />
    </div>
  ) : (
    ""
  );
};

export default Loader;
