import AppRoutes from "./routes.jsx";
import Loader from "./components/ui/Loader";

function App() {
  return (
    <div className="App">
      <Loader />
      <AppRoutes />
    </div>
  );
}

export default App;
